import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
// import Img from 'gatsby-image'
import Seo from "../components/seo"
import Navigation from "../components/navigation"
import Masthead from "../components/masthead"
import GeneralTile from "../components/generaltile"
import TilesSection from "../components/tilessection"

const {
  getFileImageNode,
  getImageForName,
} = require("../components/imageutils/fileImageNode")

export default function TypeDetails(props) {
  // const data = props.data
  const { name: typeName, brandName } = props.pageContext

  const imageNodes = props.data.allFile.nodes

  // console.log("data in TypeDetails", props)

  const typeId = typeName + "123"

  const brandTechicalName = brandName.toLowerCase().replace(" ", "-")
  const typeTechnicalName = typeName.toLowerCase().replace(" ", "-")
  const brandTypeTechicalName = brandTechicalName + "_" + typeTechnicalName

  const fileImageNode = getFileImageNode(brandTypeTechicalName, imageNodes)

  const node = {
    id: typeId,
    name: typeName,
    fileImageNode: fileImageNode,
    imgFluid: getImageForName(brandName, typeName, imageNodes),
  }
  return (
    <Layout>
      <Seo title={typeName} />
      <Navigation />
      <Masthead title={typeName} />
      <TilesSection title="">
        <GeneralTile node={node} key={node.id} />
      </TilesSection>
    </Layout>
  )
}

export const query = graphql`
  query TypeDetailsQuery(
    $name: String
    $brandName: String
    $imageName: String
  ) {
    allTypesConvertedJson(
      filter: {
        types: { elemMatch: { typeName: { eq: $name } } }
        name: { eq: $brandName }
      }
    ) {
      nodes {
        name
        types {
          typeName
        }
      }
    }

    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        childImageSharp: { fluid: { originalName: { eq: $imageName } } }
      }
    ) {
      nodes {
        childImageSharp {
          fluid {
            originalName
          }
          gatsbyImageData(
            placeholder: BLURRED
            quality: 70
            blurredOptions: { width: 100 }
            # aspectRatio: 1.5
            transformOptions: { cropFocus: CENTER, fit: COVER }
            # width: 900
            # height: 600
          )
        }
      }
    }
  }
`
